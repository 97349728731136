import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export var ELocalNotificationTriggerUnit;
(function (ELocalNotificationTriggerUnit) {
  ELocalNotificationTriggerUnit["SECOND"] = "second";
  ELocalNotificationTriggerUnit["MINUTE"] = "minute";
  ELocalNotificationTriggerUnit["HOUR"] = "hour";
  ELocalNotificationTriggerUnit["DAY"] = "day";
  ELocalNotificationTriggerUnit["WEEK"] = "week";
  ELocalNotificationTriggerUnit["MONTH"] = "month";
  ELocalNotificationTriggerUnit["QUARTER"] = "quarter";
  ELocalNotificationTriggerUnit["YEAR"] = "year";
  ELocalNotificationTriggerUnit["WEEKDAY"] = "weekday";
  ELocalNotificationTriggerUnit["WEEKDAY_ORDINAL"] = "weekdayOrdinal";
  ELocalNotificationTriggerUnit["WEEK_OF_MONTH"] = "weekOfMonth";
})(ELocalNotificationTriggerUnit || (ELocalNotificationTriggerUnit = {}));
export var ILocalNotificationActionType;
(function (ILocalNotificationActionType) {
  ILocalNotificationActionType["INPUT"] = "input";
  ILocalNotificationActionType["BUTTON"] = "button";
})(ILocalNotificationActionType || (ILocalNotificationActionType = {}));
var LocalNotifications = /** @class */function (_super) {
  __extends(LocalNotifications, _super);
  function LocalNotifications() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  LocalNotifications.prototype.hasPermission = function () {
    return cordova(this, "hasPermission", {}, arguments);
  };
  LocalNotifications.prototype.requestPermission = function () {
    return cordova(this, "requestPermission", {}, arguments);
  };
  LocalNotifications.prototype.schedule = function (options) {
    return cordova(this, "schedule", {
      "sync": true
    }, arguments);
  };
  LocalNotifications.prototype.update = function (options) {
    return cordova(this, "update", {
      "sync": true
    }, arguments);
  };
  LocalNotifications.prototype.clear = function (notificationId) {
    return cordova(this, "clear", {}, arguments);
  };
  LocalNotifications.prototype.clearAll = function () {
    return cordova(this, "clearAll", {}, arguments);
  };
  LocalNotifications.prototype.cancel = function (notificationId) {
    return cordova(this, "cancel", {}, arguments);
  };
  LocalNotifications.prototype.cancelAll = function () {
    return cordova(this, "cancelAll", {}, arguments);
  };
  LocalNotifications.prototype.isPresent = function (notificationId) {
    return cordova(this, "isPresent", {}, arguments);
  };
  LocalNotifications.prototype.isScheduled = function (notificationId) {
    return cordova(this, "isScheduled", {}, arguments);
  };
  LocalNotifications.prototype.isTriggered = function (notificationId) {
    return cordova(this, "isTriggered", {}, arguments);
  };
  LocalNotifications.prototype.hasType = function (id, type) {
    return cordova(this, "hasType", {}, arguments);
  };
  LocalNotifications.prototype.getType = function (id) {
    return cordova(this, "getType", {}, arguments);
  };
  LocalNotifications.prototype.getIds = function () {
    return cordova(this, "getIds", {}, arguments);
  };
  LocalNotifications.prototype.getScheduledIds = function () {
    return cordova(this, "getScheduledIds", {}, arguments);
  };
  LocalNotifications.prototype.getTriggeredIds = function () {
    return cordova(this, "getTriggeredIds", {}, arguments);
  };
  LocalNotifications.prototype.get = function (notificationId) {
    return cordova(this, "get", {}, arguments);
  };
  LocalNotifications.prototype.getAll = function () {
    return cordova(this, "getAll", {}, arguments);
  };
  LocalNotifications.prototype.getScheduled = function (notificationId) {
    return cordova(this, "getScheduled", {}, arguments);
  };
  LocalNotifications.prototype.getTriggered = function (notificationId) {
    return cordova(this, "getTriggered", {}, arguments);
  };
  LocalNotifications.prototype.addActions = function (groupId, actions) {
    return cordova(this, "addActions", {}, arguments);
  };
  LocalNotifications.prototype.removeActions = function (groupId) {
    return cordova(this, "removeActions", {}, arguments);
  };
  LocalNotifications.prototype.hasActions = function (groupId) {
    return cordova(this, "hasActions", {}, arguments);
  };
  LocalNotifications.prototype.getDefaults = function () {
    return cordova(this, "getDefaults", {
      "sync": true
    }, arguments);
  };
  LocalNotifications.prototype.setDefaults = function (defaults) {
    return cordova(this, "setDefaults", {
      "sync": true
    }, arguments);
  };
  LocalNotifications.prototype.getAllScheduled = function () {
    return cordova(this, "getAllScheduled", {}, arguments);
  };
  LocalNotifications.prototype.getAllTriggered = function () {
    return cordova(this, "getAllTriggered", {}, arguments);
  };
  LocalNotifications.prototype.on = function (eventName) {
    return cordova(this, "on", {
      "observable": true,
      "clearFunction": "un",
      "clearWithArgs": true
    }, arguments);
  };
  LocalNotifications.prototype.fireEvent = function (eventName, args) {
    return cordova(this, "fireEvent", {
      "sync": true
    }, arguments);
  };
  LocalNotifications.prototype.fireQueuedEvents = function () {
    return cordova(this, "fireQueuedEvents", {}, arguments);
  };
  LocalNotifications.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: LocalNotifications,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  LocalNotifications.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: LocalNotifications
  });
  LocalNotifications.pluginName = "LocalNotifications";
  LocalNotifications.plugin = "cordova-plugin-local-notification";
  LocalNotifications.pluginRef = "cordova.plugins.notification.local";
  LocalNotifications.repo = "https://github.com/katzer/cordova-plugin-local-notifications";
  LocalNotifications.platforms = ["Android", "iOS", "Windows"];
  LocalNotifications = __decorate([], LocalNotifications);
  return LocalNotifications;
}(AwesomeCordovaNativePlugin);
export { LocalNotifications };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.17",
  ngImport: i0,
  type: LocalNotifications,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    hasPermission: [],
    requestPermission: [],
    schedule: [],
    update: [],
    clear: [],
    clearAll: [],
    cancel: [],
    cancelAll: [],
    isPresent: [],
    isScheduled: [],
    isTriggered: [],
    hasType: [],
    getType: [],
    getIds: [],
    getScheduledIds: [],
    getTriggeredIds: [],
    get: [],
    getAll: [],
    getScheduled: [],
    getTriggered: [],
    addActions: [],
    removeActions: [],
    hasActions: [],
    getDefaults: [],
    setDefaults: [],
    getAllScheduled: [],
    getAllTriggered: [],
    on: [],
    fireEvent: [],
    fireQueuedEvents: []
  }
});
