import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var Geolocation = /** @class */function (_super) {
  __extends(Geolocation, _super);
  function Geolocation() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Geolocation.prototype.getCurrentPosition = function (options) {
    return cordova(this, "getCurrentPosition", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  /**
   * Watch the current device's position.  Clear the watch by unsubscribing from
   * Observable changes.
   *
   * ```typescript
   * const subscription = this.geolocation.watchPosition()
   *                               .filter((p) => p.coords !== undefined) //Filter Out Errors
   *                               .subscribe(position => {
   *   console.log(position.coords.longitude + ' ' + position.coords.latitude);
   * });
   *
   * // To stop notifications
   * subscription.unsubscribe();
   * ```
   *
   * @param {GeolocationOptions} options  The [geolocation options](https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions).
   * @returns {Observable<Geoposition | PositionError>} Returns an Observable that notifies with the [position](https://developer.mozilla.org/en-US/docs/Web/API/Position) of the device, or errors.
   */
  Geolocation.prototype.watchPosition = function (options) {
    return new Observable(function (observer) {
      var watchId = navigator.geolocation.watchPosition(observer.next.bind(observer), observer.next.bind(observer), options);
      return function () {
        return navigator.geolocation.clearWatch(watchId);
      };
    });
  };
  Geolocation.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Geolocation,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  Geolocation.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Geolocation
  });
  Geolocation.pluginName = "Geolocation";
  Geolocation.plugin = "cordova-plugin-geolocation";
  Geolocation.pluginRef = "navigator.geolocation";
  Geolocation.repo = "https://github.com/apache/cordova-plugin-geolocation";
  Geolocation.install = "ionic cordova plugin add cordova-plugin-geolocation --variable GEOLOCATION_USAGE_DESCRIPTION=\"To locate you\"";
  Geolocation.installVariables = ["GEOLOCATION_USAGE_DESCRIPTION"];
  Geolocation.platforms = ["Amazon Fire OS", "Android", "Browser", "iOS", "Windows"];
  Geolocation = __decorate([], Geolocation);
  return Geolocation;
}(AwesomeCordovaNativePlugin);
export { Geolocation };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.17",
  ngImport: i0,
  type: Geolocation,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    getCurrentPosition: []
  }
});
